 <template>
  <div>
    <CModal color="danger" :show.sync="deleteModal">
      <template v-slot:header>
        <h5 class="modal-title">Delete Role</h5>
        <button
          type="button"
          aria-label="Close"
          class="close text-white"
          @click="closeModal(deleteModal)"
        >
          ×
        </button>
      </template>
      Do you want to delete "<b>{{ role ? role.name : null}}</b>" role?
      <template v-slot:footer>
        <CButton color="secondary" @click="closeModal(deleteModal)"
          >Cancel</CButton
        >

        <CButton color="secondary" class="px-4" disabled v-if="isSaving">
          <CSpinner grow size="sm" /> Deleting..
        </CButton>
        <CButton color="danger" @click="deleteRole(role)" v-else>
          Delete Role
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "RoleDelete",
  computed: {
    ...mapState({
      deleteModal: (state) => state.roles.deleteModal,
      role: (state) => state.roles.deleteRole,
      isSaving: (state) => state.roles.isSaving,
    }),
  },
  methods: {
    closeModal(bool) {
      this.$store.commit("roles/DELETE_MODAL", { bool: bool, role: null });
    },
    deleteRole(role) {
      this.$store.dispatch("roles/destroyRole", {
        role: role,
      });
    },
  },
};
</script>